
.dropdown-menu{
    .dropdown-menu {
        display: none;
        position: absolute;
        left: 100%;
        top: 0;
    }
    & > li:hover > .btn-group > .dropdown-menu {
        display: block;
    }
}
// style the nav dropdown button to make it look like a plain element
nav .btn-group > button.dropdown-toggle.dropdown-toggle-split{
    background: inherit;
    border: none;
    outline: none;
    color: inherit;
}