/*============ footer ============*/
footer{
    .logo {
        max-width: min(35vw, 250px);
        width: auto;
        height: 75px;
        img{
            object-fit: contain;
            width: 100%;
            height: 100%!important;
        }
    }
        
    
}