
/** form generic **/
label.float,
input {
    height: 40px;
    line-height: 40px;
}

label.float {
    position: absolute;
    left:0;
    text-transform: uppercase;
    color: inherit;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
}
.js-float-wrap {
    position:relative;
}
.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    left: 5px;
    padding-top: 2px;
    padding-left: 0;
    line-height: 12px;
    font-size: 0.5em;
}
