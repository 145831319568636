/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
    a {
        color: grey;
        text-decoration:none;
        img {
            vertical-align: middle;
            display: inline-block;
            transition: transform 500ms ease;
        }
        &:hover img{
            transform-origin: center;
            transform: translateX(-200%) rotate(-360deg);
        }
    }
}