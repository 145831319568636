.collapse-menu {
    .has-collapse-button {
        display: flex;
        a {
            flex-grow: 1;
        }
        button {
            svg {
                width: 1em;
                transition: transform 0.3s ease;
            }
        }
        &:not(.collapsed) button:not(.collapsed) {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}